import { default as indexA0Hhj02ESMMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/index.vue?macro=true";
import { default as _91id_93tsVCJJbEB1Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/bundle-choices/[id].vue?macro=true";
import { default as indexvwks9KjH7SMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/bundle-choices/index.vue?macro=true";
import { default as _91id_931QYFkjLbSiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/bundles/[id].vue?macro=true";
import { default as indexH63Y7hdmmIMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/bundles/index.vue?macro=true";
import { default as indexSgkbMLWWOhMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexMs984zWiNcMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93GcQbblCOLzMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93g5WXV8IphWMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexeF26NHVdnHMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexYJbMNw8IsgMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93LHPO6C3024Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexJ6BmgpegR8Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93lQFMGI1jLAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexEeIU6yb9DxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/products/index.vue?macro=true";
import { default as merchants2RHXrPtC4jMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/merchants.vue?macro=true";
import { default as _91accountId_93znyBZccTuKMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue?macro=true";
import { default as indexS4uuq8DeDuMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/account-configurations/[id]/index.vue?macro=true";
import { default as _91id_93IZM3V049WiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/account-configurations/[id].vue?macro=true";
import { default as indextqcIzPajiAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/account-configurations/index.vue?macro=true";
import { default as _91configurationId_93dN2zPXQq7eMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/bookkeeping/[configurationId].vue?macro=true";
import { default as indexltLQUPOfwTMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/bookkeeping/index.vue?macro=true";
import { default as connected_45storesGzeQ7EH6zmMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/connected-stores.vue?macro=true";
import { default as credentialsr6jhT1jSLIMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as indexBecOxWHS9CMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as menustexoURbp8KMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/menus.vue?macro=true";
import { default as order_45providershlTovYGt1vMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/order-providers.vue?macro=true";
import { default as _91id_93O0R10NRaWlMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_932n2KJjRf1zMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexfACEBu77SUMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indextgW8bSk1C8Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/index.vue?macro=true";
import { default as _91id_93WigzvxZ0ZaMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexV8ivYvMoUBMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexE3hVrpd8MpMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as _91id_93YoDTqwUoYSMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexpSVyWqYWDzMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/employees/index.vue?macro=true";
import { default as index4wqsBkVkWCMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/images/index.vue?macro=true";
import { default as _91menuCategoryId_93NtcEaW4WboMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexkA599QFsvvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/menus/[id]/index.vue?macro=true";
import { default as indexSSfATYkwHFMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/menus/index.vue?macro=true";
import { default as index69pzSFeE1pMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/payments/index.vue?macro=true";
import { default as payment_45methods9z7RvV7CjHMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/payments/payment-methods.vue?macro=true";
import { default as configurationsOzMruI090gMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/reservations/configurations.vue?macro=true";
import { default as indexiDKfCZ2pjeMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/reservations/index.vue?macro=true";
import { default as configurationsXguE8ZG8qQMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/reviews/configurations.vue?macro=true";
import { default as indexr5EeSxMdMKMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/reviews/index.vue?macro=true";
import { default as _91id_93A7LGKwQEgYMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stock-categories/[id].vue?macro=true";
import { default as _91id_93HengkBaDHwMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stock-items/[id].vue?macro=true";
import { default as _91id_93JMJb0UDAJqMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stock-statuses/[id].vue?macro=true";
import { default as stockXHAvI71OFGMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingTYucJOxUcGMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as _91floorId_936PzhSVR2A4Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/floors/[floorId].vue?macro=true";
import { default as indexnXHEcXmS24Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/floors/index.vue?macro=true";
import { default as index2knmcF0t9uMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as indexw4cH9FYuzjMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/kitchen/index.vue?macro=true";
import { default as _91kitchenStationId_93IX2CbX3nPAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/kitchen/kitchen-stations/[kitchenStationId].vue?macro=true";
import { default as _91printerId_93WyxAVUkDdTMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/printers/[printerId].vue?macro=true";
import { default as index6Fv84RJmTGMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/printers/index.vue?macro=true";
import { default as stockpX0zEmyauIMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93IWWYpqmpDVMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indexvAOQTyQ7XyMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexfcImxu2nOrMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_932iN71i7ltsMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as index0Y6t3bLCjYMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as _91id_93wYQiuTjoZ9Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexSX7TTJ1HIlMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as indexJ5zS46xUFCMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/profile/index.vue?macro=true";
import { default as downloadH9bSbu2I25Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/download.vue?macro=true";
import { default as efficiency1b9J6SEcHxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/efficiency.vue?macro=true";
import { default as indexMVJaY3wqraMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/index.vue?macro=true";
import { default as productionCmEnb2solNMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/production.vue?macro=true";
import { default as tipsaarPVqPJvrMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/tips.vue?macro=true";
import { default as indexKFxY2mKX9fMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/cancellations/index.vue?macro=true";
import { default as reasonszRR296M5tDMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/cancellations/reasons.vue?macro=true";
import { default as _91id_93pfmOA7UWD5Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/cash-transfer-categories/[id].vue?macro=true";
import { default as index85lbjprakEMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/cash-transfer-categories/index.vue?macro=true";
import { default as indexvJ8bV1QI04Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93rIi0HmsJVIMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as _91id_93cRsxMqNwCMMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93ASRNDFPcCkMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/delivery-providers/[id].vue?macro=true";
import { default as indexHSo8ECo3QxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/delivery-providers/index.vue?macro=true";
import { default as _91id_93PFMLHXTHfWMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/order-providers/[id].vue?macro=true";
import { default as indexB9lynnfmXpMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/order-providers/index.vue?macro=true";
import { default as _91id_93Th8e07Qw8wMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/orders/[id].vue?macro=true";
import { default as indexlyWKSMOqdDMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/orders/index.vue?macro=true";
import { default as index97saNLe71mMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as closing_45errorsp1lnkiJsEsMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/store-settlements/closing-errors.vue?macro=true";
import { default as indexnHmkyhJMsOMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: indexA0Hhj02ESMMeta?.name ?? "index",
    path: indexA0Hhj02ESMMeta?.path ?? "/",
    meta: indexA0Hhj02ESMMeta || {},
    alias: indexA0Hhj02ESMMeta?.alias || [],
    redirect: indexA0Hhj02ESMMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tsVCJJbEB1Meta?.name ?? "inventory-bundle-choices-id",
    path: _91id_93tsVCJJbEB1Meta?.path ?? "/inventory/bundle-choices/:id()",
    meta: _91id_93tsVCJJbEB1Meta || {},
    alias: _91id_93tsVCJJbEB1Meta?.alias || [],
    redirect: _91id_93tsVCJJbEB1Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/bundle-choices/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvwks9KjH7SMeta?.name ?? "inventory-bundle-choices",
    path: indexvwks9KjH7SMeta?.path ?? "/inventory/bundle-choices",
    meta: indexvwks9KjH7SMeta || {},
    alias: indexvwks9KjH7SMeta?.alias || [],
    redirect: indexvwks9KjH7SMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/bundle-choices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931QYFkjLbSiMeta?.name ?? "inventory-bundles-id",
    path: _91id_931QYFkjLbSiMeta?.path ?? "/inventory/bundles/:id()",
    meta: _91id_931QYFkjLbSiMeta || {},
    alias: _91id_931QYFkjLbSiMeta?.alias || [],
    redirect: _91id_931QYFkjLbSiMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/bundles/[id].vue").then(m => m.default || m)
  },
  {
    name: indexH63Y7hdmmIMeta?.name ?? "inventory-bundles",
    path: indexH63Y7hdmmIMeta?.path ?? "/inventory/bundles",
    meta: indexH63Y7hdmmIMeta || {},
    alias: indexH63Y7hdmmIMeta?.alias || [],
    redirect: indexH63Y7hdmmIMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/bundles/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgkbMLWWOhMeta?.name ?? "inventory-modifiers",
    path: indexSgkbMLWWOhMeta?.path ?? "/inventory/modifiers",
    meta: indexSgkbMLWWOhMeta || {},
    alias: indexSgkbMLWWOhMeta?.alias || [],
    redirect: indexSgkbMLWWOhMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93g5WXV8IphWMeta?.name ?? undefined,
    path: _91id_93g5WXV8IphWMeta?.path ?? "/inventory/modifiers/modifier-groups/:id()",
    meta: _91id_93g5WXV8IphWMeta || {},
    alias: _91id_93g5WXV8IphWMeta?.alias || [],
    redirect: _91id_93g5WXV8IphWMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/modifier-groups/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexMs984zWiNcMeta?.name ?? "inventory-modifiers-modifier-groups-id",
    path: indexMs984zWiNcMeta?.path ?? "",
    meta: indexMs984zWiNcMeta || {},
    alias: indexMs984zWiNcMeta?.alias || [],
    redirect: indexMs984zWiNcMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/modifier-groups/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91modifierId_93GcQbblCOLzMeta?.name ?? "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: _91modifierId_93GcQbblCOLzMeta?.path ?? "modifiers/:modifierId()",
    meta: _91modifierId_93GcQbblCOLzMeta || {},
    alias: _91modifierId_93GcQbblCOLzMeta?.alias || [],
    redirect: _91modifierId_93GcQbblCOLzMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexeF26NHVdnHMeta?.name ?? "inventory-modifiers-modifier-groups",
    path: indexeF26NHVdnHMeta?.path ?? "/inventory/modifiers/modifier-groups",
    meta: indexeF26NHVdnHMeta || {},
    alias: indexeF26NHVdnHMeta?.alias || [],
    redirect: indexeF26NHVdnHMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/modifiers/modifier-groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexYJbMNw8IsgMeta?.name ?? "inventory-products",
    path: indexYJbMNw8IsgMeta?.path ?? "/inventory/products",
    meta: indexYJbMNw8IsgMeta || {},
    alias: indexYJbMNw8IsgMeta?.alias || [],
    redirect: indexYJbMNw8IsgMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LHPO6C3024Meta?.name ?? "inventory-products-product-categories-id",
    path: _91id_93LHPO6C3024Meta?.path ?? "/inventory/products/product-categories/:id()",
    meta: _91id_93LHPO6C3024Meta || {},
    alias: _91id_93LHPO6C3024Meta?.alias || [],
    redirect: _91id_93LHPO6C3024Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/product-categories/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJ6BmgpegR8Meta?.name ?? "inventory-products-product-categories",
    path: indexJ6BmgpegR8Meta?.path ?? "/inventory/products/product-categories",
    meta: indexJ6BmgpegR8Meta || {},
    alias: indexJ6BmgpegR8Meta?.alias || [],
    redirect: indexJ6BmgpegR8Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/product-categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lQFMGI1jLAMeta?.name ?? "inventory-products-products-id",
    path: _91id_93lQFMGI1jLAMeta?.path ?? "/inventory/products/products/:id()",
    meta: _91id_93lQFMGI1jLAMeta || {},
    alias: _91id_93lQFMGI1jLAMeta?.alias || [],
    redirect: _91id_93lQFMGI1jLAMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEeIU6yb9DxMeta?.name ?? "inventory-products-products",
    path: indexEeIU6yb9DxMeta?.path ?? "/inventory/products/products",
    meta: indexEeIU6yb9DxMeta || {},
    alias: indexEeIU6yb9DxMeta?.alias || [],
    redirect: indexEeIU6yb9DxMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/inventory/products/products/index.vue").then(m => m.default || m)
  },
  {
    name: merchants2RHXrPtC4jMeta?.name ?? "merchants",
    path: merchants2RHXrPtC4jMeta?.path ?? "/merchants",
    meta: merchants2RHXrPtC4jMeta || {},
    alias: merchants2RHXrPtC4jMeta?.alias || [],
    redirect: merchants2RHXrPtC4jMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/merchants.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IZM3V049WiMeta?.name ?? undefined,
    path: _91id_93IZM3V049WiMeta?.path ?? "/point-of-sale/account-configurations/:id()",
    meta: _91id_93IZM3V049WiMeta || {},
    alias: _91id_93IZM3V049WiMeta?.alias || [],
    redirect: _91id_93IZM3V049WiMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/account-configurations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91accountId_93znyBZccTuKMeta?.name ?? "point-of-sale-account-configurations-id-accounts-accountId",
    path: _91accountId_93znyBZccTuKMeta?.path ?? "accounts/:accountId()",
    meta: _91accountId_93znyBZccTuKMeta || {},
    alias: _91accountId_93znyBZccTuKMeta?.alias || [],
    redirect: _91accountId_93znyBZccTuKMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue").then(m => m.default || m)
  },
  {
    name: indexS4uuq8DeDuMeta?.name ?? "point-of-sale-account-configurations-id",
    path: indexS4uuq8DeDuMeta?.path ?? "",
    meta: indexS4uuq8DeDuMeta || {},
    alias: indexS4uuq8DeDuMeta?.alias || [],
    redirect: indexS4uuq8DeDuMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/account-configurations/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indextqcIzPajiAMeta?.name ?? "point-of-sale-account-configurations",
    path: indextqcIzPajiAMeta?.path ?? "/point-of-sale/account-configurations",
    meta: indextqcIzPajiAMeta || {},
    alias: indextqcIzPajiAMeta?.alias || [],
    redirect: indextqcIzPajiAMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/account-configurations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93O0R10NRaWlMeta?.name ?? undefined,
    path: _91id_93O0R10NRaWlMeta?.path ?? "/point-of-sale/connections/:id()",
    meta: _91id_93O0R10NRaWlMeta || {},
    alias: _91id_93O0R10NRaWlMeta?.alias || [],
    redirect: _91id_93O0R10NRaWlMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91configurationId_93dN2zPXQq7eMeta?.name ?? "point-of-sale-connections-id-bookkeeping-configurationId",
    path: _91configurationId_93dN2zPXQq7eMeta?.path ?? "bookkeeping/:configurationId()",
    meta: _91configurationId_93dN2zPXQq7eMeta || {},
    alias: _91configurationId_93dN2zPXQq7eMeta?.alias || [],
    redirect: _91configurationId_93dN2zPXQq7eMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/bookkeeping/[configurationId].vue").then(m => m.default || m)
  },
  {
    name: indexltLQUPOfwTMeta?.name ?? "point-of-sale-connections-id-bookkeeping",
    path: indexltLQUPOfwTMeta?.path ?? "bookkeeping",
    meta: indexltLQUPOfwTMeta || {},
    alias: indexltLQUPOfwTMeta?.alias || [],
    redirect: indexltLQUPOfwTMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/bookkeeping/index.vue").then(m => m.default || m)
  },
  {
    name: connected_45storesGzeQ7EH6zmMeta?.name ?? "point-of-sale-connections-id-connected-stores",
    path: connected_45storesGzeQ7EH6zmMeta?.path ?? "connected-stores",
    meta: connected_45storesGzeQ7EH6zmMeta || {},
    alias: connected_45storesGzeQ7EH6zmMeta?.alias || [],
    redirect: connected_45storesGzeQ7EH6zmMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/connected-stores.vue").then(m => m.default || m)
  },
  {
    name: credentialsr6jhT1jSLIMeta?.name ?? "point-of-sale-connections-id-credentials",
    path: credentialsr6jhT1jSLIMeta?.path ?? "credentials",
    meta: credentialsr6jhT1jSLIMeta || {},
    alias: credentialsr6jhT1jSLIMeta?.alias || [],
    redirect: credentialsr6jhT1jSLIMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/credentials.vue").then(m => m.default || m)
  },
  {
    name: indexBecOxWHS9CMeta?.name ?? "point-of-sale-connections-id",
    path: indexBecOxWHS9CMeta?.path ?? "",
    meta: indexBecOxWHS9CMeta || {},
    alias: indexBecOxWHS9CMeta?.alias || [],
    redirect: indexBecOxWHS9CMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: menustexoURbp8KMeta?.name ?? "point-of-sale-connections-id-menus",
    path: menustexoURbp8KMeta?.path ?? "menus",
    meta: menustexoURbp8KMeta || {},
    alias: menustexoURbp8KMeta?.alias || [],
    redirect: menustexoURbp8KMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/menus.vue").then(m => m.default || m)
  },
  {
    name: order_45providershlTovYGt1vMeta?.name ?? "point-of-sale-connections-id-order-providers",
    path: order_45providershlTovYGt1vMeta?.path ?? "order-providers",
    meta: order_45providershlTovYGt1vMeta || {},
    alias: order_45providershlTovYGt1vMeta?.alias || [],
    redirect: order_45providershlTovYGt1vMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/[id]/order-providers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91driver_932n2KJjRf1zMeta?.name ?? "point-of-sale-connections-create-driver",
    path: _91driver_932n2KJjRf1zMeta?.path ?? "/point-of-sale/connections/create/:driver()",
    meta: _91driver_932n2KJjRf1zMeta || {},
    alias: _91driver_932n2KJjRf1zMeta?.alias || [],
    redirect: _91driver_932n2KJjRf1zMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/create/[driver].vue").then(m => m.default || m)
  },
  {
    name: indexfACEBu77SUMeta?.name ?? "point-of-sale-connections-create",
    path: indexfACEBu77SUMeta?.path ?? "/point-of-sale/connections/create",
    meta: indexfACEBu77SUMeta || {},
    alias: indexfACEBu77SUMeta?.alias || [],
    redirect: indexfACEBu77SUMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/create/index.vue").then(m => m.default || m)
  },
  {
    name: indextgW8bSk1C8Meta?.name ?? "point-of-sale-connections",
    path: indextgW8bSk1C8Meta?.path ?? "/point-of-sale/connections",
    meta: indextgW8bSk1C8Meta || {},
    alias: indextgW8bSk1C8Meta?.alias || [],
    redirect: indextgW8bSk1C8Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/connections/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WigzvxZ0ZaMeta?.name ?? "point-of-sale-discounts-discounts-id",
    path: _91id_93WigzvxZ0ZaMeta?.path ?? "/point-of-sale/discounts/discounts/:id()",
    meta: _91id_93WigzvxZ0ZaMeta || {},
    alias: _91id_93WigzvxZ0ZaMeta?.alias || [],
    redirect: _91id_93WigzvxZ0ZaMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/discounts/discounts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexV8ivYvMoUBMeta?.name ?? "point-of-sale-discounts-discounts",
    path: indexV8ivYvMoUBMeta?.path ?? "/point-of-sale/discounts/discounts",
    meta: indexV8ivYvMoUBMeta || {},
    alias: indexV8ivYvMoUBMeta?.alias || [],
    redirect: indexV8ivYvMoUBMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/discounts/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexE3hVrpd8MpMeta?.name ?? "point-of-sale-discounts",
    path: indexE3hVrpd8MpMeta?.path ?? "/point-of-sale/discounts",
    meta: indexE3hVrpd8MpMeta || {},
    alias: indexE3hVrpd8MpMeta?.alias || [],
    redirect: indexE3hVrpd8MpMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YoDTqwUoYSMeta?.name ?? "point-of-sale-employees-id",
    path: _91id_93YoDTqwUoYSMeta?.path ?? "/point-of-sale/employees/:id()",
    meta: _91id_93YoDTqwUoYSMeta || {},
    alias: _91id_93YoDTqwUoYSMeta?.alias || [],
    redirect: _91id_93YoDTqwUoYSMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/employees/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpSVyWqYWDzMeta?.name ?? "point-of-sale-employees",
    path: indexpSVyWqYWDzMeta?.path ?? "/point-of-sale/employees",
    meta: indexpSVyWqYWDzMeta || {},
    alias: indexpSVyWqYWDzMeta?.alias || [],
    redirect: indexpSVyWqYWDzMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/employees/index.vue").then(m => m.default || m)
  },
  {
    name: index4wqsBkVkWCMeta?.name ?? "point-of-sale-images",
    path: index4wqsBkVkWCMeta?.path ?? "/point-of-sale/images",
    meta: index4wqsBkVkWCMeta || {},
    alias: index4wqsBkVkWCMeta?.alias || [],
    redirect: index4wqsBkVkWCMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/images/index.vue").then(m => m.default || m)
  },
  {
    name: _91menuCategoryId_93NtcEaW4WboMeta?.name ?? "point-of-sale-menus-id-categories-menuCategoryId",
    path: _91menuCategoryId_93NtcEaW4WboMeta?.path ?? "/point-of-sale/menus/:id()/categories/:menuCategoryId()",
    meta: _91menuCategoryId_93NtcEaW4WboMeta || {},
    alias: _91menuCategoryId_93NtcEaW4WboMeta?.alias || [],
    redirect: _91menuCategoryId_93NtcEaW4WboMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/menus/[id]/categories/[menuCategoryId].vue").then(m => m.default || m)
  },
  {
    name: indexkA599QFsvvMeta?.name ?? "point-of-sale-menus-id",
    path: indexkA599QFsvvMeta?.path ?? "/point-of-sale/menus/:id()",
    meta: indexkA599QFsvvMeta || {},
    alias: indexkA599QFsvvMeta?.alias || [],
    redirect: indexkA599QFsvvMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/menus/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSSfATYkwHFMeta?.name ?? "point-of-sale-menus",
    path: indexSSfATYkwHFMeta?.path ?? "/point-of-sale/menus",
    meta: indexSSfATYkwHFMeta || {},
    alias: indexSSfATYkwHFMeta?.alias || [],
    redirect: indexSSfATYkwHFMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/menus/index.vue").then(m => m.default || m)
  },
  {
    name: index69pzSFeE1pMeta?.name ?? "point-of-sale-payments",
    path: index69pzSFeE1pMeta?.path ?? "/point-of-sale/payments",
    meta: index69pzSFeE1pMeta || {},
    alias: index69pzSFeE1pMeta?.alias || [],
    redirect: index69pzSFeE1pMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/payments/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45methods9z7RvV7CjHMeta?.name ?? "point-of-sale-payments-payment-methods",
    path: payment_45methods9z7RvV7CjHMeta?.path ?? "/point-of-sale/payments/payment-methods",
    meta: payment_45methods9z7RvV7CjHMeta || {},
    alias: payment_45methods9z7RvV7CjHMeta?.alias || [],
    redirect: payment_45methods9z7RvV7CjHMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/payments/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: configurationsOzMruI090gMeta?.name ?? "point-of-sale-reservations-configurations",
    path: configurationsOzMruI090gMeta?.path ?? "/point-of-sale/reservations/configurations",
    meta: configurationsOzMruI090gMeta || {},
    alias: configurationsOzMruI090gMeta?.alias || [],
    redirect: configurationsOzMruI090gMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/reservations/configurations.vue").then(m => m.default || m)
  },
  {
    name: indexiDKfCZ2pjeMeta?.name ?? "point-of-sale-reservations",
    path: indexiDKfCZ2pjeMeta?.path ?? "/point-of-sale/reservations",
    meta: indexiDKfCZ2pjeMeta || {},
    alias: indexiDKfCZ2pjeMeta?.alias || [],
    redirect: indexiDKfCZ2pjeMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: configurationsXguE8ZG8qQMeta?.name ?? "point-of-sale-reviews-configurations",
    path: configurationsXguE8ZG8qQMeta?.path ?? "/point-of-sale/reviews/configurations",
    meta: configurationsXguE8ZG8qQMeta || {},
    alias: configurationsXguE8ZG8qQMeta?.alias || [],
    redirect: configurationsXguE8ZG8qQMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/reviews/configurations.vue").then(m => m.default || m)
  },
  {
    name: indexr5EeSxMdMKMeta?.name ?? "point-of-sale-reviews",
    path: indexr5EeSxMdMKMeta?.path ?? "/point-of-sale/reviews",
    meta: indexr5EeSxMdMKMeta || {},
    alias: indexr5EeSxMdMKMeta?.alias || [],
    redirect: indexr5EeSxMdMKMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93A7LGKwQEgYMeta?.name ?? "point-of-sale-stock-categories-id",
    path: _91id_93A7LGKwQEgYMeta?.path ?? "/point-of-sale/stock-categories/:id()",
    meta: _91id_93A7LGKwQEgYMeta || {},
    alias: _91id_93A7LGKwQEgYMeta?.alias || [],
    redirect: _91id_93A7LGKwQEgYMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stock-categories/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HengkBaDHwMeta?.name ?? "point-of-sale-stock-items-id",
    path: _91id_93HengkBaDHwMeta?.path ?? "/point-of-sale/stock-items/:id()",
    meta: _91id_93HengkBaDHwMeta || {},
    alias: _91id_93HengkBaDHwMeta?.alias || [],
    redirect: _91id_93HengkBaDHwMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stock-items/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93JMJb0UDAJqMeta?.name ?? "point-of-sale-stock-statuses-id",
    path: _91id_93JMJb0UDAJqMeta?.path ?? "/point-of-sale/stock-statuses/:id()",
    meta: _91id_93JMJb0UDAJqMeta || {},
    alias: _91id_93JMJb0UDAJqMeta?.alias || [],
    redirect: _91id_93JMJb0UDAJqMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stock-statuses/[id].vue").then(m => m.default || m)
  },
  {
    name: stockXHAvI71OFGMeta?.name ?? "point-of-sale-stock",
    path: stockXHAvI71OFGMeta?.path ?? "/point-of-sale/stock",
    meta: stockXHAvI71OFGMeta || {},
    alias: stockXHAvI71OFGMeta?.alias || [],
    redirect: stockXHAvI71OFGMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stock.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IWWYpqmpDVMeta?.name ?? undefined,
    path: _91id_93IWWYpqmpDVMeta?.path ?? "/point-of-sale/stores/:id()",
    meta: _91id_93IWWYpqmpDVMeta || {},
    alias: _91id_93IWWYpqmpDVMeta?.alias || [],
    redirect: _91id_93IWWYpqmpDVMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id].vue").then(m => m.default || m),
    children: [
  {
    name: bookkeepingTYucJOxUcGMeta?.name ?? "point-of-sale-stores-id-bookkeeping",
    path: bookkeepingTYucJOxUcGMeta?.path ?? "bookkeeping",
    meta: bookkeepingTYucJOxUcGMeta || {},
    alias: bookkeepingTYucJOxUcGMeta?.alias || [],
    redirect: bookkeepingTYucJOxUcGMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/bookkeeping.vue").then(m => m.default || m)
  },
  {
    name: _91floorId_936PzhSVR2A4Meta?.name ?? "point-of-sale-stores-id-floors-floorId",
    path: _91floorId_936PzhSVR2A4Meta?.path ?? "floors/:floorId()",
    meta: _91floorId_936PzhSVR2A4Meta || {},
    alias: _91floorId_936PzhSVR2A4Meta?.alias || [],
    redirect: _91floorId_936PzhSVR2A4Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/floors/[floorId].vue").then(m => m.default || m)
  },
  {
    name: indexnXHEcXmS24Meta?.name ?? "point-of-sale-stores-id-floors",
    path: indexnXHEcXmS24Meta?.path ?? "floors",
    meta: indexnXHEcXmS24Meta || {},
    alias: indexnXHEcXmS24Meta?.alias || [],
    redirect: indexnXHEcXmS24Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/floors/index.vue").then(m => m.default || m)
  },
  {
    name: index2knmcF0t9uMeta?.name ?? "point-of-sale-stores-id",
    path: index2knmcF0t9uMeta?.path ?? "",
    meta: index2knmcF0t9uMeta || {},
    alias: index2knmcF0t9uMeta?.alias || [],
    redirect: index2knmcF0t9uMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4cH9FYuzjMeta?.name ?? "point-of-sale-stores-id-kitchen",
    path: indexw4cH9FYuzjMeta?.path ?? "kitchen",
    meta: indexw4cH9FYuzjMeta || {},
    alias: indexw4cH9FYuzjMeta?.alias || [],
    redirect: indexw4cH9FYuzjMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/kitchen/index.vue").then(m => m.default || m)
  },
  {
    name: _91kitchenStationId_93IX2CbX3nPAMeta?.name ?? "point-of-sale-stores-id-kitchen-kitchen-stations-kitchenStationId",
    path: _91kitchenStationId_93IX2CbX3nPAMeta?.path ?? "kitchen/kitchen-stations/:kitchenStationId()",
    meta: _91kitchenStationId_93IX2CbX3nPAMeta || {},
    alias: _91kitchenStationId_93IX2CbX3nPAMeta?.alias || [],
    redirect: _91kitchenStationId_93IX2CbX3nPAMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/kitchen/kitchen-stations/[kitchenStationId].vue").then(m => m.default || m)
  },
  {
    name: _91printerId_93WyxAVUkDdTMeta?.name ?? "point-of-sale-stores-id-printers-printerId",
    path: _91printerId_93WyxAVUkDdTMeta?.path ?? "printers/:printerId()",
    meta: _91printerId_93WyxAVUkDdTMeta || {},
    alias: _91printerId_93WyxAVUkDdTMeta?.alias || [],
    redirect: _91printerId_93WyxAVUkDdTMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/printers/[printerId].vue").then(m => m.default || m)
  },
  {
    name: index6Fv84RJmTGMeta?.name ?? "point-of-sale-stores-id-printers",
    path: index6Fv84RJmTGMeta?.path ?? "printers",
    meta: index6Fv84RJmTGMeta || {},
    alias: index6Fv84RJmTGMeta?.alias || [],
    redirect: index6Fv84RJmTGMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/printers/index.vue").then(m => m.default || m)
  },
  {
    name: stockpX0zEmyauIMeta?.name ?? "point-of-sale-stores-id-stock",
    path: stockpX0zEmyauIMeta?.path ?? "stock",
    meta: stockpX0zEmyauIMeta || {},
    alias: stockpX0zEmyauIMeta?.alias || [],
    redirect: stockpX0zEmyauIMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/[id]/stock.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexvAOQTyQ7XyMeta?.name ?? "point-of-sale-stores",
    path: indexvAOQTyQ7XyMeta?.path ?? "/point-of-sale/stores",
    meta: indexvAOQTyQ7XyMeta || {},
    alias: indexvAOQTyQ7XyMeta?.alias || [],
    redirect: indexvAOQTyQ7XyMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/stores/index.vue").then(m => m.default || m)
  },
  {
    name: indexfcImxu2nOrMeta?.name ?? "point-of-sale-terminals-colors",
    path: indexfcImxu2nOrMeta?.path ?? "/point-of-sale/terminals/colors",
    meta: indexfcImxu2nOrMeta || {},
    alias: indexfcImxu2nOrMeta?.alias || [],
    redirect: indexfcImxu2nOrMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/colors/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_932iN71i7ltsMeta?.name ?? "point-of-sale-terminals-folders-id",
    path: _91id_932iN71i7ltsMeta?.path ?? "/point-of-sale/terminals/folders/:id()",
    meta: _91id_932iN71i7ltsMeta || {},
    alias: _91id_932iN71i7ltsMeta?.alias || [],
    redirect: _91id_932iN71i7ltsMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/folders/[id].vue").then(m => m.default || m)
  },
  {
    name: index0Y6t3bLCjYMeta?.name ?? "point-of-sale-terminals-folders",
    path: index0Y6t3bLCjYMeta?.path ?? "/point-of-sale/terminals/folders",
    meta: index0Y6t3bLCjYMeta || {},
    alias: index0Y6t3bLCjYMeta?.alias || [],
    redirect: index0Y6t3bLCjYMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/folders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wYQiuTjoZ9Meta?.name ?? "point-of-sale-terminals-terminals-id",
    path: _91id_93wYQiuTjoZ9Meta?.path ?? "/point-of-sale/terminals/terminals/:id()",
    meta: _91id_93wYQiuTjoZ9Meta || {},
    alias: _91id_93wYQiuTjoZ9Meta?.alias || [],
    redirect: _91id_93wYQiuTjoZ9Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/terminals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSX7TTJ1HIlMeta?.name ?? "point-of-sale-terminals-terminals",
    path: indexSX7TTJ1HIlMeta?.path ?? "/point-of-sale/terminals/terminals",
    meta: indexSX7TTJ1HIlMeta || {},
    alias: indexSX7TTJ1HIlMeta?.alias || [],
    redirect: indexSX7TTJ1HIlMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/point-of-sale/terminals/terminals/index.vue").then(m => m.default || m)
  },
  {
    name: indexJ5zS46xUFCMeta?.name ?? "profile",
    path: indexJ5zS46xUFCMeta?.path ?? "/profile",
    meta: indexJ5zS46xUFCMeta || {},
    alias: indexJ5zS46xUFCMeta?.alias || [],
    redirect: indexJ5zS46xUFCMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: downloadH9bSbu2I25Meta?.name ?? "reports-download",
    path: downloadH9bSbu2I25Meta?.path ?? "/reports/download",
    meta: downloadH9bSbu2I25Meta || {},
    alias: downloadH9bSbu2I25Meta?.alias || [],
    redirect: downloadH9bSbu2I25Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/download.vue").then(m => m.default || m)
  },
  {
    name: efficiency1b9J6SEcHxMeta?.name ?? "reports-efficiency",
    path: efficiency1b9J6SEcHxMeta?.path ?? "/reports/efficiency",
    meta: efficiency1b9J6SEcHxMeta || {},
    alias: efficiency1b9J6SEcHxMeta?.alias || [],
    redirect: efficiency1b9J6SEcHxMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/efficiency.vue").then(m => m.default || m)
  },
  {
    name: indexMVJaY3wqraMeta?.name ?? "reports",
    path: indexMVJaY3wqraMeta?.path ?? "/reports",
    meta: indexMVJaY3wqraMeta || {},
    alias: indexMVJaY3wqraMeta?.alias || [],
    redirect: indexMVJaY3wqraMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: productionCmEnb2solNMeta?.name ?? "reports-production",
    path: productionCmEnb2solNMeta?.path ?? "/reports/production",
    meta: productionCmEnb2solNMeta || {},
    alias: productionCmEnb2solNMeta?.alias || [],
    redirect: productionCmEnb2solNMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/production.vue").then(m => m.default || m)
  },
  {
    name: tipsaarPVqPJvrMeta?.name ?? "reports-tips",
    path: tipsaarPVqPJvrMeta?.path ?? "/reports/tips",
    meta: tipsaarPVqPJvrMeta || {},
    alias: tipsaarPVqPJvrMeta?.alias || [],
    redirect: tipsaarPVqPJvrMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/reports/tips.vue").then(m => m.default || m)
  },
  {
    name: indexKFxY2mKX9fMeta?.name ?? "settlement-cancellations",
    path: indexKFxY2mKX9fMeta?.path ?? "/settlement/cancellations",
    meta: indexKFxY2mKX9fMeta || {},
    alias: indexKFxY2mKX9fMeta?.alias || [],
    redirect: indexKFxY2mKX9fMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/cancellations/index.vue").then(m => m.default || m)
  },
  {
    name: reasonszRR296M5tDMeta?.name ?? "settlement-cancellations-reasons",
    path: reasonszRR296M5tDMeta?.path ?? "/settlement/cancellations/reasons",
    meta: reasonszRR296M5tDMeta || {},
    alias: reasonszRR296M5tDMeta?.alias || [],
    redirect: reasonszRR296M5tDMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/cancellations/reasons.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pfmOA7UWD5Meta?.name ?? "settlement-cash-transfer-categories-id",
    path: _91id_93pfmOA7UWD5Meta?.path ?? "/settlement/cash-transfer-categories/:id()",
    meta: _91id_93pfmOA7UWD5Meta || {},
    alias: _91id_93pfmOA7UWD5Meta?.alias || [],
    redirect: _91id_93pfmOA7UWD5Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/cash-transfer-categories/[id].vue").then(m => m.default || m)
  },
  {
    name: index85lbjprakEMeta?.name ?? "settlement-cash-transfer-categories",
    path: index85lbjprakEMeta?.path ?? "/settlement/cash-transfer-categories",
    meta: index85lbjprakEMeta || {},
    alias: index85lbjprakEMeta?.alias || [],
    redirect: index85lbjprakEMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/cash-transfer-categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cRsxMqNwCMMeta?.name ?? undefined,
    path: _91id_93cRsxMqNwCMMeta?.path ?? "/settlement/channel-settlements/:id()",
    meta: _91id_93cRsxMqNwCMMeta || {},
    alias: _91id_93cRsxMqNwCMMeta?.alias || [],
    redirect: _91id_93cRsxMqNwCMMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/channel-settlements/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexvJ8bV1QI04Meta?.name ?? "settlement-channel-settlements-id",
    path: indexvJ8bV1QI04Meta?.path ?? "",
    meta: indexvJ8bV1QI04Meta || {},
    alias: indexvJ8bV1QI04Meta?.alias || [],
    redirect: indexvJ8bV1QI04Meta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/channel-settlements/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91channelSettlementSnapshotId_93rIi0HmsJVIMeta?.name ?? "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: _91channelSettlementSnapshotId_93rIi0HmsJVIMeta?.path ?? "snapshots/:channelSettlementSnapshotId()",
    meta: _91channelSettlementSnapshotId_93rIi0HmsJVIMeta || {},
    alias: _91channelSettlementSnapshotId_93rIi0HmsJVIMeta?.alias || [],
    redirect: _91channelSettlementSnapshotId_93rIi0HmsJVIMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93ASRNDFPcCkMeta?.name ?? "settlement-delivery-providers-id",
    path: _91id_93ASRNDFPcCkMeta?.path ?? "/settlement/delivery-providers/:id()",
    meta: _91id_93ASRNDFPcCkMeta || {},
    alias: _91id_93ASRNDFPcCkMeta?.alias || [],
    redirect: _91id_93ASRNDFPcCkMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/delivery-providers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHSo8ECo3QxMeta?.name ?? "settlement-delivery-providers",
    path: indexHSo8ECo3QxMeta?.path ?? "/settlement/delivery-providers",
    meta: indexHSo8ECo3QxMeta || {},
    alias: indexHSo8ECo3QxMeta?.alias || [],
    redirect: indexHSo8ECo3QxMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/delivery-providers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PFMLHXTHfWMeta?.name ?? "settlement-order-providers-id",
    path: _91id_93PFMLHXTHfWMeta?.path ?? "/settlement/order-providers/:id()",
    meta: _91id_93PFMLHXTHfWMeta || {},
    alias: _91id_93PFMLHXTHfWMeta?.alias || [],
    redirect: _91id_93PFMLHXTHfWMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/order-providers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexB9lynnfmXpMeta?.name ?? "settlement-order-providers",
    path: indexB9lynnfmXpMeta?.path ?? "/settlement/order-providers",
    meta: indexB9lynnfmXpMeta || {},
    alias: indexB9lynnfmXpMeta?.alias || [],
    redirect: indexB9lynnfmXpMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/order-providers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Th8e07Qw8wMeta?.name ?? "settlement-orders-id",
    path: _91id_93Th8e07Qw8wMeta?.path ?? "/settlement/orders/:id()",
    meta: _91id_93Th8e07Qw8wMeta || {},
    alias: _91id_93Th8e07Qw8wMeta?.alias || [],
    redirect: _91id_93Th8e07Qw8wMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlyWKSMOqdDMeta?.name ?? "settlement-orders",
    path: indexlyWKSMOqdDMeta?.path ?? "/settlement/orders",
    meta: indexlyWKSMOqdDMeta || {},
    alias: indexlyWKSMOqdDMeta?.alias || [],
    redirect: indexlyWKSMOqdDMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/orders/index.vue").then(m => m.default || m)
  },
  {
    name: index97saNLe71mMeta?.name ?? "settlement-store-settlements-id",
    path: index97saNLe71mMeta?.path ?? "/settlement/store-settlements/:id()",
    meta: index97saNLe71mMeta || {},
    alias: index97saNLe71mMeta?.alias || [],
    redirect: index97saNLe71mMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/store-settlements/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: closing_45errorsp1lnkiJsEsMeta?.name ?? "settlement-store-settlements-closing-errors",
    path: closing_45errorsp1lnkiJsEsMeta?.path ?? "/settlement/store-settlements/closing-errors",
    meta: closing_45errorsp1lnkiJsEsMeta || {},
    alias: closing_45errorsp1lnkiJsEsMeta?.alias || [],
    redirect: closing_45errorsp1lnkiJsEsMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/store-settlements/closing-errors.vue").then(m => m.default || m)
  },
  {
    name: indexnHmkyhJMsOMeta?.name ?? "settlement-store-settlements",
    path: indexnHmkyhJMsOMeta?.path ?? "/settlement/store-settlements",
    meta: indexnHmkyhJMsOMeta || {},
    alias: indexnHmkyhJMsOMeta?.alias || [],
    redirect: indexnHmkyhJMsOMeta?.redirect,
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20240918130424/pages/settlement/store-settlements/index.vue").then(m => m.default || m)
  }
]